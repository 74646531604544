import * as React from 'react';
import { useParams } from 'react-router-dom';
import Footer from 'src/components/footer';
import Header from 'src/components/header';
import LoadingScreen from 'src/components/loadingBar';
import { PortfolioDetailsService } from 'src/libs/portfolios/portfolioDetails';
interface PortfolioDetailProps { }

const PortfolioDetail = (props: PortfolioDetailProps) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [portfolios, setPortfolios] = React.useState<any>({});
    const [category, setCategory] = React.useState<[]>([]);
    const urlId: any = useParams();
    const imgUrl = "https://letsbloggers.com/apriorit/public/assets/images/";
    const [imageArrayOne, setImageArrayOne] = React.useState<[]>([]);
    const [imageArraySecond, setImageArraySecond] = React.useState<[]>([]);
    const [nextProject, setNextProjectName] = React.useState("");
    const [nextProjectId, setNextProjectId] = React.useState("");
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "Portfolio|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    React.useEffect(() => {
        let res = (new PortfolioDetailsService).getPortfolioById(urlId?.id);
        res.then((value) => {
            setPortfolios(value.portfolio);
            setCategory(value.portfolio.projectmeta);
            setNextProjectName(value.nextprojectname);
            setNextProjectId(value.nextprojectid);
        });
    }, [])
    React.useEffect(() => {
        if (portfolios) {
            const multiImg = portfolios?.multi_image_section_1?.split(",");
            setImageArrayOne(multiImg);
            const multiImgSecond = portfolios?.multi_image_section_2?.split(",");
            setImageArraySecond(multiImgSecond)
        }
    }, [portfolios])
    return (
        <>
            <Header homePage />
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
            <main>
                <section className="case pyoor">
                    <div className="case-head" >
                        <img className="case-head__icon" style={{ borderRadius: 50, height: 100, width: 100 }} src={portfolios?.image} alt="Pyoor" />
                        <h2 className="case-head__title">{portfolios?.name}</h2>
                        <h6 className="case-head__tech">{portfolios?.tags}</h6>
                    </div>
                    <div className="case-product container">
                        <div className="case-product__content">
                            <h3 className="case-product__title">Product</h3>
                            <p className="case-product__text">{portfolios?.description}</p>
                            <a className="case-product__link" href="javascript:void(0)" rel="noopener noreferrer">
                                <span className="case-product__link_span">Go to Platform</span>
                                <svg className="case-product__link_arrow" width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0V9Z" fill="white"></path>
                                </svg>
                            </a>
                        </div>
                        <div className="case-product__image"><img className="case-product__source" src={portfolios?.image} alt="" /></div>
                    </div>
                    <div className="container">
                        <div className="case-techList">
                            {
                                category && category.length > 0 && (
                                    category.map((item: any, index: any) => (
                                        <div className="case-techList__item" key={index}>
                                            <h3 className="case-techList__title">{item?.tag_info.name}</h3>
                                            <p className="case-techList__text">{item?.tag_info.description}</p>
                                            <h6 className="case-techList__tools">{item && item.tool_data.length > 0 && 'Tools we use:'}</h6>
                                            <div className="case-techListTools">
                                                {
                                                    item && item.tool_data.length > 0 && (
                                                        item.tool_data.map((tools: any, i: any) => (
                                                            <div className="case-techListTools__item" key={i}>
                                                                <img className="case-techListTools__icon" src={tools.image} alt="" />
                                                                <h5 className="case-techListTools__title">{tools.name}</h5>
                                                            </div>

                                                        ))
                                                    )
                                                }

                                            </div>
                                        </div>
                                    ))
                                )
                            }


                        </div>
                    </div>
                    <div className="case-gallery">
                        <div className="case-gallery__wrapper container">
                            <div className="case-gallery__block ">
                                <h3 className="case-gallery__title">{portfolios.type == "app" ? "Android Desgin" : "Desktop Design"}</h3>
                                <div className="case-galleryImage">
                                    {
                                        imageArrayOne && imageArrayOne.length > 0 && (
                                            imageArrayOne.map((img, i) => (

                                                <div key={i} className="case-galleryImage__image"><img className="case-galleryImage__source" src={`${imgUrl}${img}`} alt="" /></div>
                                            ))
                                        )
                                    }

                                </div>
                            </div>
                            <div className="case-gallery__block ">
                                <h3 className="case-gallery__title">{portfolios.type == "app" ? "IOS Design" : "Adaptive design"}</h3>
                                <div className="case-galleryImage">
                                    {
                                        imageArraySecond && imageArraySecond.length > 0 && (
                                            imageArraySecond.map((img, i) => (

                                                <div key={i} className="case-galleryImage__image"><img className="case-galleryImage__source" src={`${imgUrl}${img}`} alt="" /></div>
                                            ))
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        nextProject !== "" && <div className="case-next">
                            <h5 className="case-next__title">Next project</h5>
                            <a className="case-next__link" href={`${PortfolioDetail.SCREENNAME}/${nextProjectId}`}>{nextProject}</a>
                        </div>
                    }

                    {/* <div className="case-more">
                            <div className="case-more__wrapper container">
                                <h5 className="case-more__title">More projects</h5>
                                <div className="case-more__block"><a className="case-more__link" href="/cases/startify/">Startify</a><a className="case-more__link" href="/cases/astrolife/">AstroLife</a><a className="case-more__link" href="/cases/kyiv-metro-app/">Kyiv Metro</a><a className="case-more__link" href="/cases/znaj-ua/">ZNAJ.UA</a></div>
                            </div>
                        </div> */}

                </section>
            </main>
            <Footer />
        </>
    );
};
PortfolioDetail.SCREENNAME = "/portfolioDetail"
export default PortfolioDetail;
