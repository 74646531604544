import { BaseService } from "../baseService"


export class BlogDetailsService extends BaseService {

    constructor() {
        super("singleblog")
    }

    async getBlogById(id: any) {
        let response = await this.http.get('', id);
        if (response && response.status) { }
        return response
    }

}