import axios from "axios";
import React from "react";
import Toaster from "../toaster";
const ROOT_URL = "https://letsbloggers.com/apriorit/api/";
export class HttpService {

    headers = {}
    url_prefix: string;

    constructor(url_prefix = "") {
        this.url_prefix = url_prefix
        this.getHeaders()
    }

    async get(url: any, queryParams?: any) {
        try {
            let response = await fetch(ROOT_URL + this.getUrl(url) + `?id=${queryParams}`, {
                headers: this.headers
            })
            let jsonResponse = await response.json()
            return jsonResponse
        } catch (error) {
            console.log(error)
            return null
        }
    }

    async post(url: any, body: any, queryParams = null) {
        try {
            // let response = await fetch(ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams), {
            //     method: "POST",
            //     headers: this.headers,
            //     body: body
            // })
            let response = await axios.post(ROOT_URL + this.getUrl(url), body,{headers:this.headers} )
            let jsonResponse = await response.data;
            
            return jsonResponse
        } catch (error) {
            console.log(error);
            return null
        }

    }

    async put(url: any, body: any, queryParams = null) {
        try {
            let response = await fetch(ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams), {
                method: "PUT",
                headers: this.headers,
                body: JSON.stringify(body)
            })
            let jsonResponse = await response.json()
            return jsonResponse
        } catch (error) {
            console.log(error);
            return null
        }
    }

    async remove(url: any, queryParams = null) {
        try {
            let response = await fetch(ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams), {
                method: "DELETE",
                headers: this.headers
            })
            let jsonResponse = await response.json()
            return jsonResponse
        } catch (error) {
            console.log(error)
            return null
        }
    }

    getUrl(url: string) {
        return this.url_prefix + url
    }

    getHeaders() {
        this.headers = {
            'content-type': 'multipart/form-data',
            'Accept': 'application/json'
        }
        if (this.checkSession()) {
            let apiToken = this.getSession().api_token
            this.headers = {
                ...this.headers,
                "Authorisation": `Bearer ${apiToken}`
            }
        }
    }

    getSession() {
        let session = localStorage.getItem("SESSION")
        if (session) {
            return JSON.parse(session)
        }
        return session
    }

    checkSession() {
        return localStorage.getItem("SESSION") !== null
    }

    mapQueryParams(queryParams: { [x: string]: string; } | null) {
        return queryParams
            ? Object.keys(queryParams).map(function (key) {
                return key + '=' + queryParams[key]
            }).join('&')
            : ""
    }
}
