import React, { useState } from "react";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingScreen from "src/components/loadingBar";
import UIUXScreen from "../uiux";

interface MobileDevelopementProps { }

const MobileDevelopementScreen = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "MobileDevelopment|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    return (
        <>
            <Header />

            <main>
                <section className="service">
                    <div className="mobile-development container">
                        <h2 className="service__title">Mobile development</h2>
                        <p className="service__text">We deploy leading tools to prototype and implement native iOS and Android mobile applications bringing top-notch user experience through mobile technologies. Mobile application integration helps to attract and retain your customers, while establishing your image, sale of goods and services, and communication with customers of your website.
                            alpstech team offers unique services when it comes to mobile development. Not only can we efficiently develop your product, but also will help with user testing in order to ensure that the quality of the design meets and exceeds indsutry standards. The final product is delivered upon a scrupulous QA testing. Upon accepting the transmission of the code and design, we also grant you full proprietary rights on the product.
                        </p>
                        <div className="service-tech">
                            <div className="service-tech__block">
                                <h4 className="service-tech__title">iOS</h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Swift.svg" alt="Swift" />
                                        <h6 className="service-techList__name">Swift</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Objective.svg" alt="Objective-C" />
                                        <h6 className="service-techList__name">Objective-C</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="service-tech__block">
                                <h4 className="service-tech__title">Android</h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Java.svg" alt="Java" />
                                        <h6 className="service-techList__name">Java</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Kotlin.svg" alt="Kotlin" />
                                        <h6 className="service-techList__name">Kotlin</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="service-tech__block">
                                <h4 className="service-tech__title">Crossplatform</h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/React-Native.svg" alt="React Native" />
                                        <h6 className="service-techList__name">React Native</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/flutter_icon.svg" alt="Flutter" />
                                        <h6 className="service-techList__name">Flutter</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className="service__subtitle">Expertise at those technologies helps us to create</h4>
                        <ul className="service-help">
                            <li className="service-help__item">Fitness apps</li>
                            <li className="service-help__item">Mobile ecommerce apps</li>
                            <li className="service-help__item">Social applications</li>
                            <li className="service-help__item">Financial apps</li>
                            <li className="service-help__item">Social network apps</li>
                            <li className="service-help__item">IOT based apps</li>
                            <li className="service-help__item">Healthcare apps</li>
                            <li className="service-help__item">News apps</li>
                            <li className="service-help__item">Media apps</li>
                            <li className="service-help__item">Mobile marketplaces</li>
                            <li className="service-help__item">Navigation apps</li>
                            <li className="service-help__item">Education apps</li>
                        </ul>
                        <a className="service-link" href={UIUXScreen.SCREENNAME}><span className="service-link__span">UI/UX design and expertise</span><img className="service-link__arrow" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3LjcwNzEgOC43MDcxMUM0OC4wOTc2IDguMzE2NTggNDguMDk3NiA3LjY4MzQyIDQ3LjcwNzEgNy4yOTI4OUw0MS4zNDMxIDAuOTI4OTMyQzQwLjk1MjYgMC41Mzg0MDggNDAuMzE5NSAwLjUzODQwOCAzOS45Mjg5IDAuOTI4OTMyQzM5LjUzODQgMS4zMTk0NiAzOS41Mzg0IDEuOTUyNjIgMzkuOTI4OSAyLjM0MzE1TDQ1LjU4NTggOEwzOS45Mjg5IDEzLjY1NjlDMzkuNTM4NCAxNC4wNDc0IDM5LjUzODQgMTQuNjgwNSAzOS45Mjg5IDE1LjA3MTFDNDAuMzE5NSAxNS40NjE2IDQwLjk1MjYgMTUuNDYxNiA0MS4zNDMxIDE1LjA3MTFMNDcuNzA3MSA4LjcwNzExWk0wIDlINDdWN0gwVjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K" alt="" /></a>
                    </div>
                </section>
            </main>
            <Footer />
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
        </>
    )
}

MobileDevelopementScreen.SCREENNAME = "/mobileDevelopement";
export default MobileDevelopementScreen;
