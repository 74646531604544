import React, { useState } from "react";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingScreen from "src/components/loadingBar";
import WebDevelopmentScreen from "../webDevelopment";

const QualityAssurance = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "QA|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    return (
        <>
            <Header />
            <main>
                <section className="service">
                    <div className="quality-assurance container">
                        <h2 className="service__title">Quality Assurance</h2>
                        <p className="service__text">alpstech offers Quality Assurance (QA) services to bring perfection to every project in which your product is engaged. To put it differently, this service provides various types of quality assurance testing for software, web and mobile. We pay close attention to quality management competency in order to assure predictable quality of our deliverables as well as services and solutions of our clients. We focus on both directions – Quality Assurance to setup proper quality management processes, and Software Testing  to assure control of product/service releases.
                            We cover manual- and automated testing and usually include multiple types of testing depending on software requirement and complexity including but not limited to: smoke testing, functional testing, UI and usability testing, integration testing, regression testing, performance and stress testing, load testing, security testing.
                        </p>
                        <div className="service-tech">
                            <div className="service-tech__block">
                                <h4 className="service-tech__title">Testing</h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/manual.svg" alt="Manual" />
                                        <h6 className="service-techList__name">Manual</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/automatic.svg" alt="Automatic" />
                                        <h6 className="service-techList__name">Automatic</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a className="service-link" href={WebDevelopmentScreen.SCREENNAME}><span className="service-link__span">Web-development</span><img className="service-link__arrow" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3LjcwNzEgOC43MDcxMUM0OC4wOTc2IDguMzE2NTggNDguMDk3NiA3LjY4MzQyIDQ3LjcwNzEgNy4yOTI4OUw0MS4zNDMxIDAuOTI4OTMyQzQwLjk1MjYgMC41Mzg0MDggNDAuMzE5NSAwLjUzODQwOCAzOS45Mjg5IDAuOTI4OTMyQzM5LjUzODQgMS4zMTk0NiAzOS41Mzg0IDEuOTUyNjIgMzkuOTI4OSAyLjM0MzE1TDQ1LjU4NTggOEwzOS45Mjg5IDEzLjY1NjlDMzkuNTM4NCAxNC4wNDc0IDM5LjUzODQgMTQuNjgwNSAzOS45Mjg5IDE1LjA3MTFDNDAuMzE5NSAxNS40NjE2IDQwLjk1MjYgMTUuNDYxNiA0MS4zNDMxIDE1LjA3MTFMNDcuNzA3MSA4LjcwNzExWk0wIDlINDdWN0gwVjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K" alt="" /></a>
                    </div>
                </section>
            </main>
            <Footer />
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
        </>
    )
}
QualityAssurance.SCREENNAME = "/qualityAssurance";
export default QualityAssurance;
