import React, { useState } from "react";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingScreen from "src/components/loadingBar";
import MobileDevelopementScreen from "../mobiledevelopment";

const WebDevelopmentScreen = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "Web|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    return (
        <>
            <Header />

            <main>
                <section className="service">
                    <div className="web-development container">
                        <h2 className="service__title">Web-development</h2>
                        <p className="service__text">We have the expertise in our team to help you with website development and design services as per your business needs and requirements. We will understand your business approach and assess the competition outside to get your website developed as per your vision and ensure that it ticks all the boxes to keep you ahead.
                        </p>
                        <p className="service__text">Whatever be the kind of website you need, we have the expertise to deliver the best. Our high quality web application will be taking care of all your specifications and help you remain as step ahead in the competition.
                        </p>
                        <div className="service-tech">
                            <div className="service-tech__block">
                                <h4 className="service-tech__title">Front-end technologies</h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Html.svg" alt="Html" />
                                        <h6 className="service-techList__name">Html</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Css.svg" alt="Css" />
                                        <h6 className="service-techList__name">Css</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/jquery.svg" alt="JQuery" />
                                        <h6 className="service-techList__name">JQuery</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Javascript.svg" alt="Javascript" />
                                        <h6 className="service-techList__name">Javascript</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/React.svg" alt="React" />
                                        <h6 className="service-techList__name">React</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Redux.svg" alt="Redux" />
                                        <h6 className="service-techList__name">Redux</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/angularJS.svg" alt="Angular" />
                                        <h6 className="service-techList__name">Angular</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/vueJS.svg" alt="Vue" />
                                        <h6 className="service-techList__name">Vue</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/backboneJS.svg" alt="Backbone" />
                                        <h6 className="service-techList__name">Backbone</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/emberJS.svg" alt="Ember" />
                                        <h6 className="service-techList__name">Ember</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="service-tech__block">
                                <h4 className="service-tech__title">Back-end technologies</h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Python.svg" alt="Python" />
                                        <h6 className="service-techList__name">Python</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Java.svg" alt="Java" />
                                        <h6 className="service-techList__name">Java</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/laravel.svg" alt="Laravel" />
                                        <h6 className="service-techList__name">Laravel</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Ruby on Rails.svg" alt="Ruby on Rails" />
                                        <h6 className="service-techList__name">Ruby on Rails</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/nodeJS.svg" alt="NodeJS" />
                                        <h6 className="service-techList__name">NodeJS</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Flask.svg" alt="Flask" />
                                        <h6 className="service-techList__name">Flask</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className="service__subtitle">Expertise at those technologies helps us to create</h4>
                        <ul className="service-help">
                            <li className="service-help__item">Banking & Financial Services</li>
                            <li className="service-help__item">Healthcare</li>
                            <li className="service-help__item">Education</li>
                            <li className="service-help__item">Insurance</li>
                            <li className="service-help__item">Public Sector</li>
                            <li className="service-help__item">Retail</li>
                            <li className="service-help__item">Telecom</li>
                            <li className="service-help__item">Construction</li>
                            <li className="service-help__item">Social Networking</li>
                            <li className="service-help__item">Transportation</li>
                        </ul>
                        <a className="service-link" href={MobileDevelopementScreen.SCREENNAME}><span className="service-link__span">Mobile development</span><img className="service-link__arrow" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3LjcwNzEgOC43MDcxMUM0OC4wOTc2IDguMzE2NTggNDguMDk3NiA3LjY4MzQyIDQ3LjcwNzEgNy4yOTI4OUw0MS4zNDMxIDAuOTI4OTMyQzQwLjk1MjYgMC41Mzg0MDggNDAuMzE5NSAwLjUzODQwOCAzOS45Mjg5IDAuOTI4OTMyQzM5LjUzODQgMS4zMTk0NiAzOS41Mzg0IDEuOTUyNjIgMzkuOTI4OSAyLjM0MzE1TDQ1LjU4NTggOEwzOS45Mjg5IDEzLjY1NjlDMzkuNTM4NCAxNC4wNDc0IDM5LjUzODQgMTQuNjgwNSAzOS45Mjg5IDE1LjA3MTFDNDAuMzE5NSAxNS40NjE2IDQwLjk1MjYgMTUuNDYxNiA0MS4zNDMxIDE1LjA3MTFMNDcuNzA3MSA4LjcwNzExWk0wIDlINDdWN0gwVjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K" alt="" /></a>
                    </div>
                </section>
            </main>
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
            <Footer />
        </>
    )
}
WebDevelopmentScreen.SCREENNAME = "/webDevelopment";
export default WebDevelopmentScreen;
