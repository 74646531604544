import React from 'react';
import './App.css';
import HosRoutes from './navigation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div className="App">
      <HosRoutes />
      <ToastContainer toastClassName="toaster" />
    </div>
  );
}

export default App;
