import React, { useState } from "react";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingScreen from "src/components/loadingBar";
import QualityAssurance from "../quality-assurence";

const StartupIdeaScreen = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "Startup|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    return (
        <>
            <Header />

            <main>
                <section className="service">
                    <div className="startup-idea container">
                        <h2 className="service__title">Startup idea launchpad</h2>
                        <p className="service__text">We love startups. Our ingrained mission is to upgrade the entrepreneurship ecosystem and make resources available to anyone who wants to turn their ideas into reality. That is why, we launched a program that facilitates development of your company.
                            Our team will work closely with you to develop an MVP to test the market and reach out to investors. We will offer not only design and development services, but fully integrate with your team providing R&amp;D, consulting, and operations management free of charge. We hope that this program will allow entrepreneurs to start regardless of the budget they have, that is why our rates are exclusively adjusted to the current needs and budget constraints you may have. Let’s work together on upgrading the startup ecosystem worldwide through mutual help and technological advancement.
                        </p>
                        <h4 className="service__subtitle">Expertise at those technologies helps us to create</h4>
                        <ul className="service-help">
                            <li className="service-help__item">Valuable MVPs</li>
                            <li className="service-help__item">Crypto Startups</li>
                            <li className="service-help__item">E-commerce startups</li>
                            <li className="service-help__item">Product Startups</li>
                            <li className="service-help__item">Healthcare startups</li>
                            <li className="service-help__item">AI based startups</li>
                            <li className="service-help__item">Financial startups</li>
                            <li className="service-help__item">Startups promotion</li>
                        </ul>
                        <a className="service-link" href={QualityAssurance.SCREENNAME}><span className="service-link__span">Quality Assurance</span><img className="service-link__arrow" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3LjcwNzEgOC43MDcxMUM0OC4wOTc2IDguMzE2NTggNDguMDk3NiA3LjY4MzQyIDQ3LjcwNzEgNy4yOTI4OUw0MS4zNDMxIDAuOTI4OTMyQzQwLjk1MjYgMC41Mzg0MDggNDAuMzE5NSAwLjUzODQwOCAzOS45Mjg5IDAuOTI4OTMyQzM5LjUzODQgMS4zMTk0NiAzOS41Mzg0IDEuOTUyNjIgMzkuOTI4OSAyLjM0MzE1TDQ1LjU4NTggOEwzOS45Mjg5IDEzLjY1NjlDMzkuNTM4NCAxNC4wNDc0IDM5LjUzODQgMTQuNjgwNSAzOS45Mjg5IDE1LjA3MTFDNDAuMzE5NSAxNS40NjE2IDQwLjk1MjYgMTUuNDYxNiA0MS4zNDMxIDE1LjA3MTFMNDcuNzA3MSA4LjcwNzExWk0wIDlINDdWN0gwVjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K" alt="" /></a>
                    </div>
                </section>
            </main>
            <Footer />
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
        </>
    )
}
StartupIdeaScreen.SCREENNAME = "/startupIdea";
export default StartupIdeaScreen;
