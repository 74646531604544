import React, { useState } from "react";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingScreen from "src/components/loadingBar";
import StartupIdeaScreen from "../startup";

interface BrandingAndAdsProps { }

const BrandingAndAdsScreen = (props:BrandingAndAdsProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "Branding|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    return (
        <>
            <Header />

            <main>
                <section className="service">
                    <div className="branding-and-ads container">
                        <h2 className="service__title">Branding and advertising</h2>
                        <p className="service__text">Our creative team is a fast-moving advertising vehicle  that creates, services, develops and transforms brands. We guarantee to transform your brand into one that thrives in our changing world. Our brands  exceed creative expectations and live their social responsibility.
                            We combine old-fashioned creative storytelling with the leading technology on the market. In a world where people distrust big brother, big brands and big data, we develop brands that people trust, connect with, and simply love. You will recieve smart strategies, prompt execution and content that captures hearts and minds. Advertising for the digital age. Our crew is made up of digitally native professionals.
                        </p>
                        <div className="service-tech">
                            <div className="service-tech__block">
                                <h4 className="service-tech__title">Branding</h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/photoshop.svg" alt="Photoshop" />
                                        <h6 className="service-techList__name">Photoshop</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Illustrator.svg" alt="Illustrator" />
                                        <h6 className="service-techList__name">Illustrator</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/ae.svg" alt="After Effects" />
                                        <h6 className="service-techList__name">After Effects</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="service-tech__block">
                                <h4 className="service-tech__title">Advertising</h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/fb.svg" alt="Facebook Ads" />
                                        <h6 className="service-techList__name">Facebook</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/ga.svg" alt="Google Ads" />
                                        <h6 className="service-techList__name">Google</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/instagram_color.svg" alt="Instagram Ads" />
                                        <h6 className="service-techList__name">Instagram</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className="service__subtitle">Expertise at those technologies helps us to create</h4>
                        <ul className="service-help">
                            <li className="service-help__item">Brand strategy</li>
                            <li className="service-help__item">Poster design</li>
                            <li className="service-help__item">Product marketing strategy</li>
                            <li className="service-help__item">Logo design</li>
                            <li className="service-help__item">Advertising banners</li>
                            <li className="service-help__item">Customers analysis</li>
                            <li className="service-help__item">Identity design</li>
                            <li className="service-help__item">Advertising campaigns plans</li>
                            <li className="service-help__item">Stationery design</li>
                            <li className="service-help__item">Product development strategy</li>
                        </ul>
                        <a className="service-link" href={StartupIdeaScreen.SCREENNAME}><span className="service-link__span">Startup idea launchpad</span><img className="service-link__arrow" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3LjcwNzEgOC43MDcxMUM0OC4wOTc2IDguMzE2NTggNDguMDk3NiA3LjY4MzQyIDQ3LjcwNzEgNy4yOTI4OUw0MS4zNDMxIDAuOTI4OTMyQzQwLjk1MjYgMC41Mzg0MDggNDAuMzE5NSAwLjUzODQwOCAzOS45Mjg5IDAuOTI4OTMyQzM5LjUzODQgMS4zMTk0NiAzOS41Mzg0IDEuOTUyNjIgMzkuOTI4OSAyLjM0MzE1TDQ1LjU4NTggOEwzOS45Mjg5IDEzLjY1NjlDMzkuNTM4NCAxNC4wNDc0IDM5LjUzODQgMTQuNjgwNSAzOS45Mjg5IDE1LjA3MTFDNDAuMzE5NSAxNS40NjE2IDQwLjk1MjYgMTUuNDYxNiA0MS4zNDMxIDE1LjA3MTFMNDcuNzA3MSA4LjcwNzExWk0wIDlINDdWN0gwVjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K" alt="" /></a>
                    </div>
                </section>
            </main>
            <Footer />
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
        </>
    )
}
BrandingAndAdsScreen.SCREENNAME = "/branding";
export default BrandingAndAdsScreen;
