import { BaseService } from "../baseService"
import Toaster from "../toaster";


export class ContactService extends BaseService {

    constructor() {
        super("contactus")
    }

    async submitContactForm(body: any) {
        let response = await this.http.post("", body);
        if(response&&response.status){
            Toaster.show("We appreciate you contacting us alpstech. One of our colleagues will get back in touch with you soon!Have a great day! ")
        }
        return response
    }

}
