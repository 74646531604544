import React, { useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";

interface LoadingBarProps {
    loading: boolean;
}

const LoadingScreen = ({ loading }: LoadingBarProps) => {
    const ref = useRef<any>();
    useEffect(() => {
        if (loading) {
            ref.current.complete()
        }
    }, [])
    return (
        <div>
            <LoadingBar color="#1958e3" ref={ref} height={7} shadow={true} />
            <div className="brandNameBox">
                <div className="brandBox">
                <div className="logo"><img src="../images/logo.svg" alt="" style={{ maxWidth: 200, }} /></div>
                </div>
            </div>
        </div>
    )
}
export default LoadingScreen;