import React, { useState } from "react";
import { ContactService } from "src/libs/contactHttpCall";

interface ModalProps {
   onClose: () => void;
   display: string;

};

const Modal = ({ onClose, display }: ModalProps) => {
   const [name, setName] = useState<string>("");
   const [email, setEmail] = useState<string>("");
   const [phone, setPhone] = useState<string>("");
   const [describe, setDescribe] = useState<string>("");
   const [file, setFile] = useState<File | any>();
   const handleChange = (e: any) => {
      const file = e.target.files[0];
      setFile(file);
   }
   const handleSubmit = async () => {
      if (name !== "" && email !== "" && phone !== "") {
         var formData = new FormData();
         formData.append('name', name || "");
         formData.append('email', email || "");
         formData.append('mobile', phone || "");
         formData.append('description', describe || "");
         formData.append('image', file || "");
         let res = (new ContactService).submitContactForm(formData);
         res.then((value) => {
            if (value && value.status) {
               onClose();
            }
         })
      }
   }
   return (
      <main>
         <div className="modal" style={{ display: display }}>
            <div className="modal__wrapper" >
               <svg onClick={() => onClose()} className="modal__close" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.0442 2.54463L23.4527 0.953125L12.9977 11.4081L2.54267 0.953125L0.951172 2.54463L11.4062 12.9996L0.951172 23.4546L2.54267 25.0461L12.9977 14.5911L23.4527 25.0461L25.0442 23.4546L14.5892 12.9996L25.0442 2.54463Z" fill="#1958E3"></path>
               </svg>
               <h4 className="modal__title">Strong Junior/Middle UI/UX developer</h4>
               <form className="modal-form">
                  <input className="modal-form__name" type="text" name="name" onChange={(e) => setName(e.target.value)} placeholder="Name" required value={name} />
                  <input className="modal-form__mail" type="email" name="mail" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" required value={email} />
                  <input className="modal-form__phone" type="tel" name="phone" onChange={(e) => setPhone(e.target.value)} placeholder="Phone" value={phone} />
                  <textarea className="modal-form__text" name="text" onChange={(e) => setDescribe(e.target.value)} placeholder="Tell us about yourself" value={describe}></textarea>
                  <a className="modal-form__submit" href="javascript:void0()" style={{ textAlign: "center", textDecorationLine: "none" }} onClick={() => handleSubmit()} >Send</a>
                  <label className="modal-form-file">
                     <input className="modal-form-file__input" accept=".doc,.docx,application/pdf" type="file" onChange={(e) => handleChange(e)} />
                     <span className="modal-form-file__span">
                        <svg className="modal-form-file__icon" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <circle cx="24" cy="24" r="24" fill="#1958E3" fillOpacity="0.1"></circle>
                           <path d="M28.2294 25.3471L24.0014 21.1191L19.7734 25.3471L20.8344 26.4081L23.2514 23.9911V35.2501H24.7514V23.9911L27.1684 26.4081L28.2294 25.3471Z" fill="#1958E3"></path>
                           <path d="M30.751 19.568V19.5C30.751 15.778 27.723 12.75 24.001 12.75C20.279 12.75 17.251 15.778 17.251 19.5V19.617C16.1397 19.8271 15.1152 20.3608 14.306 21.151L14.307 21.15C13.8143 21.6271 13.4225 22.1983 13.1551 22.8298C12.8876 23.4613 12.7498 24.1402 12.75 24.826C12.75 26.285 13.349 27.674 14.438 28.739C15.502 29.779 16.938 30.375 18.378 30.375H21.375V28.875H18.378C16.179 28.875 14.25 26.983 14.25 24.826C14.25 22.845 15.921 21.174 18.053 21.022L18.75 20.972V19.5C18.75 18.1076 19.3031 16.7723 20.2877 15.7877C21.2723 14.8031 22.6076 14.25 24 14.25C25.3924 14.25 26.7277 14.8031 27.7123 15.7877C28.6969 16.7723 29.25 18.1076 29.25 19.5V21.002L29.991 21.011C32.169 21.039 33.75 22.643 33.75 24.826C33.75 27.097 32.079 28.875 29.945 28.875H26.625V30.375H29.962C30.673 30.3757 31.3764 30.2287 32.0276 29.9433C32.6788 29.6579 33.2636 29.2403 33.745 28.717L33.748 28.713C34.716 27.672 35.249 26.292 35.249 24.826C35.249 22.087 33.361 19.94 30.749 19.568H30.751Z" fill="#1958E3"></path>
                        </svg>
                     </span>
                     <span className="modal-form-file__text">Attach CV</span>
                  </label>
               </form>
            </div>
         </div>
      </main>
   )

}
export default Modal;