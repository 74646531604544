import React, { useEffect, useRef, useState } from "react";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingBar from 'react-top-loading-bar';
import BlogBanner from "./assets/blogbanner.jpeg"
import LoadingScreen from "src/components/loadingBar";
import BlogDetails from "./screens";
import { BlogService } from "src/libs/blogsServices";
import { useHistory } from "react-router-dom";
interface BlogsProps { };

const BlogScreen = (props: BlogsProps) => {
    const ref = useRef<any>()
    const loadingRef = useRef<any>()
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [blogData, setblogData] = useState<[]>([]);
    const [latestBlog, setLatestBlog] = useState<{}|any>();
    const history = useHistory();
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "Blog|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    useEffect(() => {
        let res = (new BlogService).getBlogList();
        res.then((value) => {
            setblogData(value.blogs);
        });
    }, [])
    useEffect(() => {
        if (blogData && blogData.length > 0) {
            const data = blogData.find(e => !!e);
            setLatestBlog(data)
        }
    }, [blogData,latestBlog]);
    return (
        <>

            <Header />
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
            <main>
                <section className="blog">
                    <div className="blog__wrapper container">
                        <h2 className="blog__title">Check our latest news and updates</h2>
                        <a className="blog-head" href="javascript:void(0)" style={{ backgroundImage: ` url(${latestBlog?.image})` }} >
                            <h3 className="blog-head__title">{latestBlog?.name}</h3>
                            <p className="blog-head__text">What Will Be Next?</p>
                            <a className="blog-head__link" href={`${BlogDetails.SCREENNAME}/${latestBlog?.id}`}>Read an article</a>
                        </a>
                        <h3 className="blog__subtitle">More articles</h3>
                        <div className="blog__block">
                            {
                                blogData && blogData.length > 0 && (
                                    blogData.map((item: any, index: number) => (
                                        <a className="blog-item" style={{width:index==0?`${50}%`:''}} key={index} href="javascript:void(0)">
                                            <div className="blog-item__block" onClick={()=>history.push(`${BlogDetails.SCREENNAME}/${item.id}`)} >
                                                <p className="blog-item__view">Read an article</p>
                                                <img className="blog-item__image" src={item.image} alt="" />
                                            </div>
                                            <div className="blog-item__block">
                                                <span className="blog-item__tag blog-item__tag_business">{item.tags}</span>
                                            </div>
                                            <h5 className="blog-item__title">{item.name}</h5>
                                            <div className="blog-author">
                                                <div className="blog-author__image"><img className="blog-author__source" src={item?.user?.image} alt="" /></div>
                                                <div className="blog-author__block">
                                                    <h6 className="blog-author__title">{item?.user?.name}</h6>
                                                    <p className="blog-author__text">{item?.user?.type}</p>
                                                </div>
                                            </div>
                                        </a>

                                    ))
                                )
                            }

                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}
BlogScreen.SCREENNAME = "/blog";
export default BlogScreen;
