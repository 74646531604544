import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AboutScreen from 'src/features/about';
import BlogScreen from 'src/features/blog';
import CareerScreen from 'src/features/career';
import ProjectReqScreen from 'src/features/projectRequest';
import ContactForm from '../contactForm';

interface HeaderProps {
    scrolled?: boolean;
    homePage?: boolean
}
function Header({ homePage }: HeaderProps) {
    const [light, setLightClass] = useState<string>("");
    const [navItemClass, setnavItemClass] = useState<string>(homePage ? "" : "dark");
    const [contactPageShown, setContactPageShown] = useState<boolean>(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 60) {
                setnavItemClass("dark")
                setLightClass('scrolled');
            } else {
                setLightClass('');
                setnavItemClass(homePage ? "" : "dark")
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    useEffect(() => {
        if (contactPageShown) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "visible";
        }
    }, [contactPageShown])

    return (
        <header className={`header ${light}`}>
            <nav className="nav container">
                <a aria-current="page" className="" href="/">
                    <div className="logo"><img src="../images/logo.svg" alt="" style={{ maxWidth: 100, }} /></div>
                </a>
                <ul className="nav-list">
                    <li className="nav-list__item"><Link className={`nav-list__link ${navItemClass}`} to={AboutScreen.SCREENNAME}>About us</Link></li>
                    <li className="nav-list__item"><Link className={`nav-list__link ${navItemClass}`} to={ProjectReqScreen.SCREENNAME}>Project request</Link></li>
                    <li className="nav-list__item"><Link className={`nav-list__link ${navItemClass}`} to={CareerScreen.SCREENNAME}>We are hiring</Link></li>
                    {/* <li className="nav-list__item"><Link className={`nav-list__link ${navItemClass}`} to={BlogScreen.SCREENNAME}>Blog</Link></li> */}
                </ul>
                <div className="nav__button" onClick={() => {
                    setContactPageShown(true); setnavItemClass("dark")
                    setLightClass('scrolled');
                }} >Contact us</div>
            </nav>
            {
                contactPageShown && <ContactForm />
            }
        </header>
    );
}

export default Header;