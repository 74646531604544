interface IndustryProps{
    name:string;
    id:number;
    image:any
}
export  const industryData:IndustryProps[] = [
    {
        name:"Healthcare",
        id:1,
        image:"../images/healthcare.svg"
    },
    {
        name:"Financing",
        id:2,
        image:"../images/finance.svg"
    },
    {
        name:"Science",
        id:3,
        image:"../images/science.svg"
    },
    {
        name:"Ecology",
        id:4,
        image:"../images/ecology.svg"
    },
    {
        name:"Education",
        id:5,
        image:"../images/education.svg"
    },
    {
        name:"Retail",
        id:6,
        image:"../images/retail.svg"
    },
    {
        name:"Business",
        id:7,
        image:"../images/busniess.svg"
    },
    {
        name:"Software",
        id:8,
        image:"../images/software.svg"
    },
    {
        name:"E-commerce",
        id:9,
        image:"../images/shopping.svg"
    },
    {
        name:"Art and culture",
        id:10,
        image:"../images/t.svg"
    }, 
    {
        name:"Startup",
        id:11,
        image:"../images/startup.svg"
    }, 
    {
        name:"Government",
        id:12,
        image:"../images/goverment.svg"
    },

]
export  const serviceData:any[] = [
    {
        name:"Web-development",
        id:1,
        image:"../images/web.svg"
    },
    {
        name:"Android app",
        id:2,
        image:"../images/android.svg"
    },
    {
        name:"iOS app",
        id:3,
        image:"../images/ios.svg"
    },
    {
        name:"UI/UX design",
        id:4,
        image:"../images/uiux.svg"
    },
    {
        name:"Branding",
        id:5,
        image:"../images/branding.svg"
    },
    {
        name:"Marketing",
        id:6,
        image:"../images/ads.svg"
    },
    {
        name:"Chatbot development",
        id:7,
        image:"../images/chatbot.svg"
    },
    {
        name:"AI development",
        id:8,
        image:"../images/ai.svg"
    },
    {
        name:"Startup development",
        id:9,
        image:"../images/startupdev.svg"
    },
    {
        name:"Programming",
        id:10,
        image:"../images/programing.svg"
    }, 
    {
        name:"Other",
        id:11,
        image:"../images/other.svg"
    }, 


]
export const deadlineData:any[]=[
    {
        heading:"Less then 1 month",
        id:1,
    },
    {
        heading:"1-3 months",
        id:2,
    }, 
    {
        heading:"3-6 months",
        id:3,
    }, 
    {
        heading:"More than 6 months",
        id:4,
    },
]
export const budgetData:any[]=[
    {
        heading:"Less then $5k",
        id:1,
    },
    {
        heading:"$5k-15k",
        id:2,
    }, 
    {
        heading:"$15k-40k",
        id:3,
    }, 
    {
        heading:"More than $40k",
        id:4,
    },
]