import React, { useState } from "react";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingScreen from "src/components/loadingBar";
import Modal from "src/components/modal";

interface CareerProps { };

const CareerScreen = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const onClose = () => {
        setShowModal(false);
    }
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "Career|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    return (
        <>
            <Header />
            <main>
                <section className="career">
                    <div className="container">
                        <h2 className="career__title">Opportunities for like-minded people</h2>
                        <h4 className="career__subtitle">Join our venturing family</h4>
                        <div className="career-info">
                            <p className="career-info__text">alpstech has revolutionized not only software outsourcing model, but also internal management. We promote a collaborative environment where colleagues are advisors who provide their assistance to each other. We believe that growing as a team is key to success. Hence, investing in the education of our employees remains our top priority.</p>
                            <p className="career-info__text">You will be immersed into our idea-generation processes, have the same impact as everyone else in the team, have freedom to express your thoughts, experiment, and handpick the projects you are most interested in. Let’s work on turning ideas into reality. Apply today to join us in enteprising with ventures of tomorrow.</p>
                        </div>
                        <div className="career-job">
                            <div className="career-job__item">
                                <div className="career-job__block">
                                    <h4 className="career-job__title">Middle Front-end developer (React.js)</h4>
                                    <p className="career-job__address">Lviv, UA</p>
                                </div>
                                <div className="career-job__block" onClick={() => { setShowModal(true) }}>
                                    <p className="career-job__apply">Apply for a job</p>
                                    <img className="career-job__icon" src="../images/arrow.svg" alt="" />
                                </div>
                            </div>
                            <div className="career-job__item">
                                <div className="career-job__block">
                                    <h4 className="career-job__title">Strong Junior/Middle UI/UX developer</h4>
                                    <p className="career-job__address">Lviv, UA</p>
                                </div>
                                <div className="career-job__block " onClick={() => { setShowModal(true) }}>
                                    <p className="career-job__apply">Apply for a job</p>
                                    <img className="career-job__icon" src="../images/arrow.svg" alt="" />
                                </div>
                            </div>
                            <div className="career-job__item">
                                <div className="career-job__block">
                                    <h4 className="career-job__title">Back-end developer (Python/Ruby on Rails)</h4>
                                    <p className="career-job__address">Lviv, UA</p>
                                </div>
                                <div className="career-job__block" onClick={() => { setShowModal(true) }}>
                                    <p className="career-job__apply">Apply for a job</p>
                                    <img className="career-job__icon" src="../images/arrow.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="whywork">
                    <div className="container">
                        <h2 className="whywork__title">Why work in alpstech</h2>
                        <div className="whywork__wrapper">
                            <div className="whywork-item">
                                <h2 className="whywork-item__title">01</h2>
                                <div className="whywork-item__block">
                                    <p className="whywork-item__text">By choosing us, you will be able to work with an internationally-awarded company that follows high industry standards for pay, work hours, and work ethic. Working with alpstech will ensure reliability to meet your professional goals.</p>
                                    <h5 className="whywork-item__slogan">Enjoy international work standards</h5>
                                </div>
                            </div>
                            <div className="whywork-item">
                                <h2 className="whywork-item__title">02</h2>
                                <div className="whywork-item__block">
                                    <p className="whywork-item__text">alpstech is not about “I” but rather about “We.” Being part of our alpstech team, you will have an opportunity to make a true impact by sharing your creative ideas and starting your own venture together with your colleagues.</p>
                                    <h5 className="whywork-item__slogan">Launch your own ventures</h5>
                                </div>
                            </div>
                            <div className="whywork-item">
                                <h2 className="whywork-item__title">03</h2>
                                <div className="whywork-item__block">
                                    <p className="whywork-item__text">Joining alpstech you will become a part of not only a great company with vast resources but also a big family. Our cooperative approach lets us achieve even the most challenging goals in the most favorable and creative ways.</p>
                                    <h5 className="whywork-item__slogan">Create life-long relationships</h5>
                                </div>
                            </div>
                            <div className="whywork-item">
                                <h2 className="whywork-item__title">04</h2>
                                <div className="whywork-item__block">
                                    <p className="whywork-item__text">If you are not fond of a typical hierarchical structure at workspace, you are just the right fit for our company. At alpstech, we have a horizontal organizational structure where accountability is equally shared.</p>
                                    <h5 className="whywork-item__slogan">Avoid corporate ladder</h5>
                                </div>
                            </div>
                            <div className="whywork-item">
                                <h2 className="whywork-item__title">05</h2>
                                <div className="whywork-item__block">
                                    <p className="whywork-item__text">A huge part of our mission is to invest in education. At alpstech, you will be able to constantly grow, accessing a vast variety of opportunities to expand your skillset together with your colleagues.</p>
                                    <h5 className="whywork-item__slogan">Grow and learn daily</h5>
                                </div>
                            </div>
                            <div className="whywork-item">
                                <h2 className="whywork-item__title">06</h2>
                                <div className="whywork-item__block">
                                    <p className="whywork-item__text">The best thing about being part of alpstech is that we always combine productive work with simply having fun. We believe it is crucial to enjoy the process to enhance creativity and problem-solving.</p>
                                    <h5 className="whywork-item__slogan">Simply have fun</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {
                showModal && <Modal display={showModal ? 'flex' : 'none'} onClose={() => { onClose() }} />
            }
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }

            <Footer />
        </>
    )
}
CareerScreen.SCREENNAME = "/career";
export default CareerScreen;
