import React, { useState } from "react";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingScreen from "src/components/loadingBar";
import BrandingAndAdsScreen from "../branding-and-ads";

const UIUXScreen = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "UIUX|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    return (
        <>
            <Header />

            <main>
                <section className="service">
                    <div className="ui-ux-design container">
                        <h2 className="service__title">UI/UX design and expertise</h2>
                        <p className="service__text">Illustrations have been in digital product design for a long time. Their evolution in the last years is very impressive. Illustrations as very popular design elements add natural feel and “human touch” to overall UX of our products. Design is also very strong attention grabbers: at the top of that by applying motion to these illustrations we bring your products to the life and make them stand out— adding extra details and personality.
                            The user interface is the graphical layout of an application. It consists of the buttons users click on, the text they read, the images, sliders, text entry fields, and all the rest of the items the user interacts with. We will design your screen layout, transitions, interface animations and every single micro-interaction. Any sort of visual element, interaction, or animation will be exclusively tailored to your product.
                        </p>
                        <div className="service-tech">
                            <div className="service-tech__block">
                                <h4 className="service-tech__title"></h4>
                                <div className="service-techList">
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/figma.svg" alt="Figma" />
                                        <h6 className="service-techList__name">Figma</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Sketch.svg" alt="Sketch" />
                                        <h6 className="service-techList__name">Sketch</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/photoshop.svg" alt="Photoshop" />
                                        <h6 className="service-techList__name">Photoshop</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Illustrator.svg" alt="Illustrator" />
                                        <h6 className="service-techList__name">Illustrator</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Zeplin.svg" alt="Zeplin" />
                                        <h6 className="service-techList__name">Zeplin</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Principle.svg" alt="Principle" />
                                        <h6 className="service-techList__name">Principle</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/ae.svg" alt="After Effects" />
                                        <h6 className="service-techList__name">After Effects</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Whimsical.png" alt="Whimsical" />
                                        <h6 className="service-techList__name">Whimsical</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/Wireflow.svg" alt="Wireflow" />
                                        <h6 className="service-techList__name">Wireflow</h6>
                                    </div>
                                    <div className="service-techList__item">
                                        <img className="service-techList__icon" src="../images/xd.svg" alt="Adobe XD" />
                                        <h6 className="service-techList__name">Adobe XD</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 className="service__subtitle">Expertise at those technologies helps us to create</h4>
                        <ul className="service-help">
                            <li className="service-help__item">Landing pages design</li>
                            <li className="service-help__item">Desktopp apps design</li>
                            <li className="service-help__item">Usability testing</li>
                            <li className="service-help__item">E-commerce design</li>
                            <li className="service-help__item">Wireframing</li>
                            <li className="service-help__item">Android design</li>
                            <li className="service-help__item">Interactive prototype</li>
                            <li className="service-help__item">iOS design</li>
                            <li className="service-help__item">Customer journey maps</li>
                        </ul>
                        <a className="service-link" href={BrandingAndAdsScreen.SCREENNAME}><span className="service-link__span">Branding and advertising</span><img className="service-link__arrow" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCA0OCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ3LjcwNzEgOC43MDcxMUM0OC4wOTc2IDguMzE2NTggNDguMDk3NiA3LjY4MzQyIDQ3LjcwNzEgNy4yOTI4OUw0MS4zNDMxIDAuOTI4OTMyQzQwLjk1MjYgMC41Mzg0MDggNDAuMzE5NSAwLjUzODQwOCAzOS45Mjg5IDAuOTI4OTMyQzM5LjUzODQgMS4zMTk0NiAzOS41Mzg0IDEuOTUyNjIgMzkuOTI4OSAyLjM0MzE1TDQ1LjU4NTggOEwzOS45Mjg5IDEzLjY1NjlDMzkuNTM4NCAxNC4wNDc0IDM5LjUzODQgMTQuNjgwNSAzOS45Mjg5IDE1LjA3MTFDNDAuMzE5NSAxNS40NjE2IDQwLjk1MjYgMTUuNDYxNiA0MS4zNDMxIDE1LjA3MTFMNDcuNzA3MSA4LjcwNzExWk0wIDlINDdWN0gwVjlaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K" alt="" /></a>
                    </div>
                </section>
            </main>
            <Footer />
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
        </>
    )
}
UIUXScreen.SCREENNAME = "/uiux";
export default UIUXScreen;
