import { BaseService } from "../baseService"


export class PortfolioDetailsService extends BaseService {

    constructor() {
        super("singleportfolio")
    }

    async getPortfolioById(id: any) {
        let response = await this.http.get('', id);
        if (response && response.status) { }
        return response
    }

}