import { BaseService } from "../baseService"


export class BlogService extends BaseService {

    constructor() {
        super("allbloglist")
    }

    async getBlogList() {
        let response = await this.http.get('');
        if(response&&response.status){ }
        return response
    }

}