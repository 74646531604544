import React from 'react';

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let config: any = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};
class ToasterService {
    async show(message: string) {
        toast.success(message, config);
    }
    async showError(message: string) {
        toast.error(message, config);
    }
}
const Toaster = new ToasterService();
export default Toaster;