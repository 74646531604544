import axios from "axios";
import React, { memo, useState } from "react";
import { ContactService } from "src/libs/contactHttpCall";
import { ProjectRequestService } from "src/libs/projectRequestHttpCall";

interface ContactFormProps {
    projectRequest?: boolean;
    industry?:any;
    service?:any;
    budget?:string;
    deadline?:string;

}

const ContactForm = ({ projectRequest ,industry,service,budget,deadline}: ContactFormProps) => {
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [describe, setDescribe] = useState<string>("");
    const [file, setFile] = useState<File | any>();
    const handleChange = (e: any) => {
        const file = e.target.files[0];
        setFile(file);
    }
    const handleSubmit = async () => {
        if (name !== "" && email !== "" && phone !== "") {
            var formData = new FormData();
            formData.append('name', name || "");
            formData.append('email', email || "");
            formData.append('mobile', phone || "");
            formData.append('company_name', company || "");
            formData.append('description', describe || "");
            formData.append('image', file || "");
            let res = (new ContactService).submitContactForm(formData);
        }
    }
    const checkSubmit = () =>{
        if(projectRequest){
            if (name !== "" && email !== "" && phone !== "") {
                var formData = new FormData();
                formData.append('name', name || "");
                formData.append('email', email || "");
                formData.append('mobile', phone || "");
                formData.append('company_name', company || "");
                formData.append('description', describe || "");
                formData.append('industry_name', JSON.stringify(industry) || "");
                formData.append('service_name', JSON.stringify(service) || "");
                formData.append('deadline_time', deadline || "");
                formData.append('project_budget', budget || "");
                formData.append('image', file || "");
                console.log(Object.fromEntries(formData))
                let res = (new ProjectRequestService).submitProjectRequest(formData);
            }
        }else{
            handleSubmit()
        }
    }
    return (
        <div id="footer-mail" className="footer-mail" style={{maxHeight:projectRequest?"100%":''}} >
            <div className="footer-mail__wrapper container">
                <div className="footer-mail__block">
                    {
                        projectRequest && <h6 className="footer-mail__subtitle">Step 5<span className="quiz__step--span">/5</span></h6>
                    }

                    <h6 className="footer-mail__subtitle">Start your project with us now</h6>
                    <h2 className="footer-mail__title">Let’s build the greatest experience for your customers</h2>
                    <p className="footer-mail__text">Email us to <a className="footer-mail__link" href="mailto:hello@alpstech.io">hello@alpstech.io</a></p>
                    {/*<p className="footer-mail__text">or call <a className="footer-mail__link" href="tel:+19292188640">+1 929-218-8640</a></p>*/}
                </div>
                <div className="footer-mail__block">
                    <form className="footer-form">
                        <div className="footer-form__block">
                            <input className="footer-form__input" type="text" name="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                            <input className="footer-form__input" type="email" name="mail" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <input className="footer-form__input" type="tel" name="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            <input className="footer-form__input" type="text" name="field" placeholder="Company name (optional)" value={company} onChange={(e) => setCompany(e.target.value)} />
                        </div>
                        <textarea className="footer-form__textarea" name="text" placeholder="Describe your project (optional)" onChange={(e) => setDescribe(e.target.value)} value={describe}></textarea>
                        <div className="footer-form__block">
                            <label htmlFor="updates" className="footer-form-checkbox">
                                <input className="footer-form-checkbox__input"  defaultChecked={true}  type="checkbox"  id="updates" />
                                <span className="footer-form-checkbox__span"></span>
                                <span className="footer-form-checkbox__text">I want to receive news and updates</span>
                            </label>
                            <label className="footer-form-file">
                                <input className="footer-form-file__input"  type="file" onChange={(e) => handleChange(e)} />
                                <span className="footer-form-file__span">
                                    <img className="footer-form-file__icon" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjIzIDEzLjM0NjlMMTIuMDAyIDkuMTE4OUw3Ljc3Mzk2IDEzLjM0NjlMOC44MzQ5NiAxNC40MDc5TDExLjI1MiAxMS45OTA5VjIzLjI0OTlIMTIuNzUyVjExLjk5MDlMMTUuMTY5IDE0LjQwNzlMMTYuMjMgMTMuMzQ2OVoiIGZpbGw9IiMxMzE3MkMiLz4KPHBhdGggZD0iTTE4Ljc1MDEgNy41NjhWNy41QzE4Ljc1MDEgMy43NzggMTUuNzIyMSAwLjc1IDEyLjAwMDEgMC43NUM4LjI3ODE1IDAuNzUgNS4yNTAxNCAzLjc3OCA1LjI1MDE0IDcuNVY3LjYxN0M0LjEzODgyIDcuODI3MTMgMy4xMTQzMSA4LjM2MDc5IDIuMzA1MTUgOS4xNTFMMi4zMDYxNSA5LjE1QzEuODEzNDQgOS42MjcwNiAxLjQyMTY5IDEwLjE5ODMgMS4xNTQyMSAxMC44Mjk4QzAuODg2NzMyIDExLjQ2MTMgMC43NDg5NzkgMTIuMTQwMiAwLjc0OTE0NiAxMi44MjZDMC43NDkxNDYgMTQuMjg1IDEuMzQ4MTUgMTUuNjc0IDIuNDM3MTUgMTYuNzM5QzMuNTAxMTUgMTcuNzc5IDQuOTM3MTQgMTguMzc1IDYuMzc3MTQgMTguMzc1SDkuMzc0MTRWMTYuODc1SDYuMzc3MTRDNC4xNzgxNSAxNi44NzUgMi4yNDkxNSAxNC45ODMgMi4yNDkxNSAxMi44MjZDMi4yNDkxNSAxMC44NDUgMy45MjAxNSA5LjE3NCA2LjA1MjE1IDkuMDIyTDYuNzQ5MTUgOC45NzJWNy41QzYuNzQ5MTUgNi4xMDc2MSA3LjMwMjI3IDQuNzcyMjYgOC4yODY4MyAzLjc4NzY5QzkuMjcxNCAyLjgwMzEyIDEwLjYwNjggMi4yNSAxMS45OTkxIDIuMjVDMTMuMzkxNSAyLjI1IDE0LjcyNjkgMi44MDMxMiAxNS43MTE1IDMuNzg3NjlDMTYuNjk2IDQuNzcyMjYgMTcuMjQ5MSA2LjEwNzYxIDE3LjI0OTEgNy41VjkuMDAyTDE3Ljk5MDEgOS4wMTFDMjAuMTY4MSA5LjAzOSAyMS43NDkxIDEwLjY0MyAyMS43NDkxIDEyLjgyNkMyMS43NDkxIDE1LjA5NyAyMC4wNzgxIDE2Ljg3NSAxNy45NDQxIDE2Ljg3NUgxNC42MjQxVjE4LjM3NUgxNy45NjExQzE4LjY3MjIgMTguMzc1NyAxOS4zNzU2IDE4LjIyODcgMjAuMDI2OCAxNy45NDMzQzIwLjY3OCAxNy42NTc5IDIxLjI2MjggMTcuMjQwMyAyMS43NDQxIDE2LjcxN0wyMS43NDcxIDE2LjcxM0MyMi43MTUxIDE1LjY3MiAyMy4yNDgxIDE0LjI5MiAyMy4yNDgxIDEyLjgyNkMyMy4yNDgxIDEwLjA4NyAyMS4zNjAxIDcuOTQgMTguNzQ4MSA3LjU2OEgxOC43NTAxWiIgZmlsbD0iIzEzMTcyQyIvPgo8L3N2Zz4K" alt="" /></span><span className="footer-form-file__text">Attach file</span></label></div>
                        <div className="footer-form__block">
                            <a className="footer-form__submit" href="javascript:void(0)" onClick={() => checkSubmit()}>Send</a>
                            <a className="footer-form__link" href="/survey/">Try our interactive survey</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default memo(ContactForm);
