import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import ContactForm from "src/components/contactForm";
import Footer from "src/components/footer";
import Header from "src/components/header";
import LoadingScreen from "src/components/loadingBar";
import { budgetData, deadlineData, industryData, serviceData } from "./data";

interface ProjectReqProps { };
interface QuizProps {
    industry: string[];
    service: string[];
}
const ProjectReqScreen = (props: ProjectReqProps) => {
    const divRef = useRef<HTMLDivElement>(null);
    const [step, setSteps] = useState<number>(1);
    const [quiz, setQuiz] = useState<QuizProps>({
        industry: [],
        service: []
    });
    const [budget, setBudget] = useState<any>("");
    const [deadline, setDeadline] = useState<any>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedData, setSelectedData] = useState<string>("")
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    const handleStep = (currentStep: number) => {
        if (step !== currentStep) setSteps(currentStep);
    }
    const handleOnChange = (data: any) => {
        console.log(data)
        const { industry } = quiz;
        const idx = industry.indexOf(data);
        if (idx >= 0) {
            industry.splice(idx, 1);
        } else {
            industry.push(data);
        }
        console.log(industry)
    }
    const handleServiceOnChange = (data: any) => {
        const { service } = quiz;
        const idx = service.indexOf(data);
        if (idx >= 0) {
            service.splice(idx, 1)
        } else {
            service.push(data)
        }
        console.log(service)
    }
    const { industry, service } = quiz;
    document.title = "Servey|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    return (
        <>
            {
                step !== 5 && <Header />
            }
            <main>
                {step !== 5 && <section className="survey" id="survey">
                    <div className="container">
                        <h2 className="survey__title">Request a project and get free estimates for timeline and pricing</h2>
                        <p className="survey__text">Let’s turn your idea into digital reality! Just answer our interactive questions and we will draft the best product offer for you. Our business analysts will study your brand, make their conclusions, and draft most suitable proposals. We provide free estimation and IT consulting for our clients. So don’t hesitate to contact us.</p>
                        <h4 className="survey__subtitle"  >Let’s try our interactive survey</h4>
                    </div>
                </section>}
                {
                    step === 1 ? (
                        <section className="quiz">
                            <div className="container" ref={divRef}>
                                <div className="">
                                    <div className="quiz__block">
                                        <h3 className="quiz__title">Choose your industry</h3>
                                        {/* <svg className="quiz__arrow hide" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="23.5" transform="matrix(-1 0 0 1 24 24)" stroke="#1958E3"></circle>
                                            <path d="M26.5 19L22 23.5L26.5 28" stroke="#1958E3" strokeWidth="3"></path>
                                        </svg> */}
                                        <svg onClick={() => {
                                            if (quiz.industry.length > 0) {
                                                handleStep(2);
                                                const height: any = document.getElementById('survey')?.clientHeight;
                                                window.scrollTo(0, height)
                                            }
                                        }} className="quiz__arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="23.5" transform="matrix(-1 0 0 1 24 24)" stroke="#1958E3"></circle>
                                            <path d="M22 19L26.5 23.5L22 28" stroke="#1958E3" strokeWidth="3"></path>
                                        </svg>
                                        <h5 className="quiz__step">step 1<span className="quiz__step--span">/5</span></h5>
                                    </div>
                                    <div className="quiz__block">
                                        {
                                            industryData && industryData.length > 0 && (
                                                industryData && industryData.map((item, index) => (
                                                    <div className="quiz-item" key={index}>
                                                        <form>
                                                            <input id={`${item.name}-${item.id}`} className="quiz-item__checkbox" name={`${item.name}-${item.id}`} type="checkbox" value={item.name}
                                                                onChange={(event) => { handleOnChange(event.target.value) }} />
                                                            <label className={`quiz-item__label ${industry.indexOf(item.name) > -1 && "active"}`} htmlFor={`${item.name}-${item.id}`}>
                                                                <img className="quiz-item__icon" src={item.image} alt="" />
                                                                <h5 className="quiz-item__title">{item.name}</h5>
                                                            </label>
                                                        </form>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                    <button className="quiz-btn" onClick={() => {
                                        if (quiz.industry.length > 0) {
                                            handleStep(2);
                                            const height: any = document.getElementById('survey')?.clientHeight;
                                            window.scrollTo(0, height)
                                        }
                                    }} >
                                        Next question
                                        <svg className="quiz-btn__svg" width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0V9Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </section>
                    ) : step === 2 ? (
                        <section className="quiz">
                            <div className="container">
                                <div className="" >
                                    <div className="quiz__block">
                                        <h3 className="quiz__title">What service do you search for ?</h3>
                                        <svg onClick={() => {
                                            handleStep(1)
                                        }} className="quiz__arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="23.5" transform="matrix(-1 0 0 1 24 24)" stroke="#1958E3"></circle>
                                            <path d="M26.5 19L22 23.5L26.5 28" stroke="#1958E3" strokeWidth="3"></path>
                                        </svg>
                                        <svg onClick={() => {
                                            if (quiz.service.length > 0) {
                                                handleStep(3);
                                                const height:any= document.getElementById('survey')?.clientHeight;
                                                window.scrollTo(0,height)
                                            }
                                        }} className="quiz__arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="24" cy="24" r="23.5" stroke="#1958E3"></circle>
                                            <path d="M22 19L26.5 23.5L22 28" stroke="#1958E3" strokeWidth="3"></path>
                                        </svg>
                                        <h5 className="quiz__step">step 2<span className="quiz__step--span">/5</span></h5>
                                    </div>
                                    <div className="quiz__block">
                                        {
                                            serviceData && serviceData.length > 0 && (
                                                serviceData && serviceData.map((item, index) => (
                                                    <div className="quiz-item" key={index}>
                                                        <input id={`quizItem-${item.id}-${item.id}`} className="quiz-item__checkbox" type="checkbox" name="quiz-2" value={item.name}
                                                            onChange={(event) => { handleServiceOnChange(event.target.value) }} />
                                                        <label className={`quiz-item__label ${service.indexOf(item.name) > -1 && "active"}`} htmlFor={`quizItem-${item.id}-${item.id}`}>
                                                            <img className="quiz-item__icon" src={item.image} alt="" />
                                                            <h5 className="quiz-item__title">{item.name}</h5>
                                                        </label>
                                                    </div>
                                                ))
                                            )
                                        }
                                    </div>
                                    <button className="quiz-btn" onClick={() => {
                                        if (quiz.service.length > 0) {
                                            handleStep(3);
                                            const height:any= document.getElementById('survey')?.clientHeight;
                                            window.scrollTo(0,height)
                                        }
                                    }}>
                                        Next question
                                        <svg className="quiz-btn__svg" width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0V9Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </section>
                    ) : step === 3 ? (
                        <section className="quiz">
                            <div className="container">
                                <div className="">
                                    <div className="quiz__block">
                                        <h3 className="quiz__title">How much time you have for a development?</h3>
                                        <svg onClick={() => {
                                            handleStep(2)
                                        }} className="quiz__arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="23.5" transform="matrix(-1 0 0 1 24 24)" stroke="#1958E3"></circle>
                                            <path d="M26.5 19L22 23.5L26.5 28" stroke="#1958E3" strokeWidth="3"></path>
                                        </svg>
                                        <svg onClick={() => {
                                            if (deadline !== "")
                                                handleStep(4)
                                        }} className="quiz__arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="24" cy="24" r="23.5" stroke="#1958E3"></circle>
                                            <path d="M22 19L26.5 23.5L22 28" stroke="#1958E3" strokeWidth="3"></path>
                                        </svg>
                                        <h5 className="quiz__step">step 3<span className="quiz__step--span">/5</span></h5>
                                    </div>
                                    <div className="quiz__block">
                                        {
                                            deadlineData && deadlineData.length > 0 && (
                                                deadlineData && deadlineData.map((item, index) => (
                                                    <div className="quiz-item" key={index} >
                                                        <input id={`quizItem-3-${item.id}`} className="quiz-item__checkbox" onChange={(e) => { setDeadline(e.target.value); console.log(e.target.value) }} value={item.heading} type="radio" name="quiz-3" />
                                                        <label className="quiz-item__label" htmlFor={`quizItem-3-${item.id}`}>
                                                            <h5 className="quiz-item__title">{item.heading}</h5>
                                                        </label>
                                                    </div>
                                                ))
                                            )
                                        }

                                    </div>
                                    <button className="quiz-btn" onClick={() => {
                                        if (deadline !== "")
                                            handleStep(4);
                                            const height:any= document.getElementById('survey')?.clientHeight;
                                            window.scrollTo(0,height)
                                    }}>
                                        Next question
                                        <svg className="quiz-btn__svg" width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0V9Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </section>
                    ) : step === 4 ? (
                        <section className="quiz">
                            <div className="container">
                                <div className="">
                                    <div className="quiz__block">
                                        <h3 className="quiz__title">What is project budget?</h3>
                                        <svg onClick={() => {
                                            handleStep(3)
                                        }} className="quiz__arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle r="23.5" transform="matrix(-1 0 0 1 24 24)" stroke="#1958E3"></circle>
                                            <path d="M26.5 19L22 23.5L26.5 28" stroke="#1958E3" strokeWidth="3"></path>
                                        </svg>
                                        <svg onClick={() => {
                                            if (budget !== "") {
                                                handleStep(5);
                                            }
                                        }
                                        } className="quiz__arrow" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="24" cy="24" r="23.5" stroke="#1958E3"></circle>
                                            <path d="M22 19L26.5 23.5L22 28" stroke="#1958E3" strokeWidth="3"></path>
                                        </svg>
                                        <h5 className="quiz__step">step 4<span className="quiz__step--span">/5</span></h5>
                                    </div>
                                    <div className="quiz__block">

                                        {
                                            budgetData && budgetData.length > 0 && (
                                                budgetData && budgetData.map((item, index) => (
                                                    <form>
                                                        <div className="quiz-item" key={index} >
                                                            <input id={`quizItem-4-${item.id}`} className="quiz-item__checkbox" onChange={(e) => setBudget(e.target.value)} value={item.heading} type="radio" name="quiz-4" />
                                                            <label className="quiz-item__label" htmlFor={`quizItem-4-${item.id}`}>
                                                                <h5 className="quiz-item__title">{item.heading}</h5>
                                                            </label>
                                                        </div>
                                                    </form>
                                                ))
                                            )
                                        }
                                    </div>
                                    <button className="quiz-btn" onClick={() => {
                                        if (budget !== "") {
                                            handleStep(5)
                                        }
                                    }
                                    }>
                                        Last step
                                        <svg className="quiz-btn__svg" width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0V9Z" fill="white"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </section>
                    ) : step === 5 && (
                        <ContactForm projectRequest industry={quiz.industry} service={quiz.service} budget={budget} deadline={deadline} />
                    )
                }
            </main>
            {
                step !== 5 && <Footer projectScreen />
            }
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
        </>
    )
}
ProjectReqScreen.SCREENNAME = "/survey";
export default ProjectReqScreen;
