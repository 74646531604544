import * as React from 'react';
import { useParams } from 'react-router-dom';
import Footer from 'src/components/footer';
import Header from 'src/components/header';
import LoadingScreen from 'src/components/loadingBar';
import { BlogDetailsService } from 'src/libs/blogsServices/BlogsDetails';
import blogBanner from '../assets/blogbanner.jpeg';
interface BlogDetailsProps { }

const BlogDetails = (props: BlogDetailsProps) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [currentBlog, setCurrentBlog] = React.useState<any>({})
    const [tags,setTags] = React.useState<[]>([])
    const urlId: any = useParams();
    if (isLoading) {
        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
    }
    document.title = "Blog|alpstech";
    document.getElementsByTagName("META")[2].textContent = "Your description about the page or site here to set dynamically";
    React.useEffect(() => {
        let res = (new BlogDetailsService).getBlogById(urlId?.id);
        res.then((value) => {
            setCurrentBlog(value.blog)
        });
    }, [])
    React.useEffect(()=>{
        var array = currentBlog?.tags?.split(',');
        setTags(array)
    },[currentBlog])
    function createMarkup(html: any) {
        return {__html: html};
      }
    return (
        <>
            {
                isLoading && <LoadingScreen loading={isLoading} />
            }
            <Header homePage />
            <main>
                <section className="article-head" style={{ backgroundImage: ` url(${currentBlog?.image})` }}>
                    <h4 className="article-head__title">{currentBlog?.name}</h4>
                    <h5 className="article-head__subtitle">{currentBlog?.sub_title}</h5>
                </section>
                <section className="article-info">
                    <div className="article-info__wrapper">
                        <div className="article-info__block">
                            <img className="article-info__photo" src={currentBlog?.user?.image} alt="Movacat" />
                            <h6 className="article-info__name">{currentBlog?.user?.name}</h6>
                            <p className="article-info__position">{currentBlog?.user?.type}</p>
                        </div>
                        <div className="article-info__block">
                            {
                                tags&&tags.length>0&&(
                                    tags.map((tag,i)=>(
                                        <p className="article-info__tag article-info__tag_development" key={i} >{tag}</p>
                                    ))
                                )
                            }
                           
                        </div>
                        <div className="article-info__block">
                            <p className="article-info__share">Share on</p>
                            <a href="https://twitter.com/intent/tweet?text=http://alpstech.io/blog/article/publishing-your-app" className="article-info__link" target="_blank" rel="noopener noreferrer">
                                <svg className="article-info__svg" width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.633 3.99704C17.646 4.17204 17.646 4.34604 17.646 4.52004C17.646 9.84504 13.593 15.981 6.186 15.981C3.904 15.981 1.784 15.32 0 14.172C0.324 14.209 0.636 14.222 0.973 14.222C2.856 14.222 4.589 13.586 5.974 12.501C4.203 12.464 2.719 11.304 2.207 9.70804C2.456 9.74504 2.706 9.77004 2.968 9.77004C3.329 9.77004 3.692 9.72004 4.029 9.63304C2.182 9.25904 0.799 7.63804 0.799 5.68004V5.63004C1.336 5.92904 1.959 6.11604 2.619 6.14104C1.534 5.41904 0.823 4.18404 0.823 2.78704C0.823 2.03904 1.022 1.35304 1.371 0.755043C3.354 3.19804 6.335 4.79504 9.677 4.97004C9.615 4.67004 9.577 4.35904 9.577 4.04704C9.577 1.82704 11.373 0.019043 13.605 0.019043C14.765 0.019043 15.812 0.505043 16.548 1.29104C17.458 1.11604 18.33 0.779043 19.104 0.318043C18.805 1.25304 18.168 2.03904 17.333 2.53804C18.144 2.45004 18.93 2.22604 19.652 1.91404C19.104 2.71204 18.419 3.42304 17.633 3.99704Z" fill="#7E8086"></path>
                                </svg>
                            </a>
                            <a href="https://www.linkedin.com/shareArticle?mini=true&amp;url=http://alpstech.io/blog/article/publishing-your-app" className="article-info__link" target="_blank" rel="noopener noreferrer">
                                <svg className="article-info__svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 0H1C0.447 0 0 0.448 0 1V17C0 17.552 0.447 18 1 18H17C17.553 18 18 17.552 18 17V1C18 0.448 17.553 0 17 0ZM5.339 15.337H2.667V6.747H5.339V15.337ZM4.003 5.574C3.147 5.574 2.455 4.88 2.455 4.026C2.455 3.172 3.146 2.478 4.003 2.478C4.857 2.478 5.551 3.171 5.551 4.026C5.551 4.881 4.857 5.574 4.003 5.574ZM15.338 15.337H12.669V11.16C12.669 10.164 12.651 8.883 11.281 8.883C9.891 8.883 9.68 9.969 9.68 11.09V15.338H7.013V6.748H9.573V7.922H9.61C9.965 7.247 10.837 6.535 12.134 6.535C14.838 6.535 15.337 8.313 15.337 10.627V15.337H15.338Z" fill="#7E8086"></path>
                                </svg>
                            </a>
                            <a href="https://www.facebook.com/sharer/sharer.php?u=http://alpstech.io/blog/article/publishing-your-app" className="article-info__link" target="_blank" rel="noopener noreferrer">
                                <svg className="article-info__svg" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17 0H1C0.447 0 0 0.448 0 1V17C0 17.552 0.447 18 1 18H9.615V11.04H7.277V8.315H9.615V6.315C9.615 3.99 11.035 2.723 13.115 2.723C13.814 2.721 14.514 2.757 15.21 2.83V5.25H13.775C12.647 5.25 12.427 5.788 12.427 6.575V8.31H15.124L14.774 11.035H12.426V18H17C17.553 18 18 17.552 18 17V1C18 0.448 17.553 0 17 0Z" fill="#7E8086"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
                </section>
                <section className="article-item">
                    <div className="article-item__letter">*</div>
                    <p dangerouslySetInnerHTML={createMarkup(currentBlog?.description)}></p>
                    </section>
                
            </main>
            <Footer />
        </>
    );
};
BlogDetails.SCREENNAME = `/blogdetails`
export default BlogDetails;
