import { BaseService } from "../baseService"
import Toaster from "../toaster";


export class PortfolioService extends BaseService {

    constructor() {
        super("portfoliolist")
    }

    async getPortfolioList() {
        let response = await this.http.get('');
        if(response&&response.status){ }
        return response
    }

}