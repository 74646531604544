import { BaseService } from "../baseService"


export class ProjectRequestService extends BaseService {

    constructor() {
        super("projectrequest")
    }

    async submitProjectRequest(body: any) {
        let response = await this.http.post("", body)
        console.log(response)
        return response
    }

}