import React from 'react';
import BrandingAndAdsScreen from 'src/features/branding-and-ads';
import MobileDevelopementScreen from 'src/features/mobiledevelopment';
import PrivacyPolicy from 'src/features/privacy-policy';
import TermsOfUse from 'src/features/privacy-policy/screen';
import QualityAssurance from 'src/features/quality-assurence';
import UIUXScreen from 'src/features/uiux';
import WebDevelopmentScreen from 'src/features/webDevelopment';
import ContactForm from '../contactForm';

interface FooterProps {
    projectScreen?:boolean
}
function Footer({projectScreen}: FooterProps) {
    return (

        <footer className="footer">
         {!projectScreen&&<ContactForm />}
            <div className="footer-main container">
                <div className="footer-main__wrapper">
                    <h2 className="footer-main__title">Design and software development <span className="footer-main__title_blue">done right</span></h2>
                    <div className="footer-main-nav">
                        <ul className="footer-main-nav__list">
                            <li className="footer-main-nav__item">Website</li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href="/aboutus">About us</a></li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href="/survey/">Try this</a></li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href="/career/">We are hiring</a></li>
                            {/* <li className="footer-main-nav__item"><a className="footer-main-nav__link" href="/blog/">Blog</a></li> */}
                            <li className="footer-main-nav__item ">
                                <a href="https://creative.alpstech.io" className="footer-main-nav__link__creative footer-main-nav__link ">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                                        <defs>
                                            <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                                                <stop offset="0%" className="footer-main-nav__link__creative__gradcolor1"></stop>
                                                <stop offset="100%" className="footer-main-nav__link__creative__gradcolor2"></stop>
                                            </linearGradient>
                                        </defs>
                                        <text fill="url(#grad1)" x="0" y="16">alpstech&nbsp;Creative</text>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                        <ul className="footer-main-nav__list">
                            <li className="footer-main-nav__item">Services</li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href={WebDevelopmentScreen.SCREENNAME}>Web development</a></li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href={MobileDevelopementScreen.SCREENNAME}>Mobile development</a></li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href={UIUXScreen.SCREENNAME}>UI/UX design</a></li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href={BrandingAndAdsScreen.SCREENNAME}>Product design</a></li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href={QualityAssurance.SCREENNAME}>QA Testing</a></li>
                        </ul>
                        <ul className="footer-main-nav__list">
                            <li className="footer-main-nav__item">Opportunities</li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href="/career/">Middle Front-end developer (React.js)</a></li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href="/career/">Strong Junior/Middle UI/UX developer</a></li>
                            <li className="footer-main-nav__item"><a className="footer-main-nav__link" href="/career/">Back-end developer (Python/Ruby on Rails)</a></li>
                        </ul>
                        <div className="footer-main-nav-media">
                            {/*<a href="https://www.behance.net/alpstech" className="footer-main-nav-media__link" target="_blank" rel="noopener noreferrer">*/}
                            {/*    <img className="footer-main-nav-media__icon" src="../images/behance.svg" alt="" />*/}
                            {/*</a>*/}
                            {/*<a href="https://dribbble.com/norrr" className="footer-main-nav-media__link" target="_blank" rel="noopener noreferrer">*/}
                            {/*    <img className="footer-main-nav-media__icon" src="../images/driggble.svg" alt="" />*/}
                            {/*</a>*/}
                            <a href="https://www.linkedin.com/company/alpstech" className="footer-main-nav-media__link" target="_blank" rel="noopener noreferrer">
                                <img className="footer-main-nav-media__icon" src="../images/linkdin.svg" alt="" />

                            </a>
                            <a href="https://www.facebook.com/alpstechio/" className="footer-main-nav-media__link" target="_blank" rel="noopener noreferrer">
                                <img className="footer-main-nav-media__icon" src="../images/facebook.svg" alt="" />
                            </a>
                            <a href="https://www.instagram.com/alpstechio/" className="footer-main-nav-media__link" target="_blank" rel="noopener noreferrer">
                                <img className="footer-main-nav-media__icon" src="../images/instagram.svg" alt="" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-main__wrapper">
                    <div className="footer-main-info">
                        <p className="footer-main-info__text">We are located in heart of Europe</p>
                        <div className="footer-main-info__block"><a className="footer-main-info__link_blue" href="https://goo.gl/maps/JTESa4KnQqtdxLi37" target="_blank" rel="noopener noreferrer">Paris, France</a></div>
                        <a className="footer-main-info__link" href="mailto:hello@alpstech.io">hello@alpstech.io</a>
                    </div>
                    <div className="footer-main__world_flex"><img className="footer-main__world" src={'../images/world-map.svg'} alt="" /></div>
                </div>
                <div className="footer-main__wrapper">
                    <p className="footer-main__data">alpstech 2021. All rights reserved.</p>
                    <a className="footer-main__data" href={TermsOfUse.SCREENNAME}>Terms of use</a><a className="footer-main__data"  href={PrivacyPolicy.SCREENNAME}>Privacy policy</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
