import * as React from "react";
import { Route, BrowserRouter as Router, Switch, Redirect, useRouteMatch, useLocation } from "react-router-dom";
import AboutScreen from "src/features/about";
import BlogScreen from "src/features/blog";
import BlogDetails from "src/features/blog/screens";
import BrandingAndAdsScreen from "src/features/branding-and-ads";
import CareerScreen from "src/features/career";
import HomeScreen from "src/features/home";
import PortfolioDetail from "src/features/home/screens";
import MobileDevelopementScreen from "src/features/mobiledevelopment";
import PrivacyPolicy from "src/features/privacy-policy";
import TermsOfUse from "src/features/privacy-policy/screen";
import ProjectReqScreen from "src/features/projectRequest";
import QualityAssurance from "src/features/quality-assurence";
import StartupIdeaScreen from "src/features/startup";
import UIUXScreen from "src/features/uiux";
import WebDevelopmentScreen from "src/features/webDevelopment";

const HosRoutes = () => {
    return (
        <Router>
            <Switch >
                <Route exact path={HomeScreen.SCREENNAME} component={HomeScreen} />
                <Route path={AboutScreen.SCREENNAME} component={AboutScreen} />
                <Route path={CareerScreen.SCREENNAME} component={CareerScreen} />
                <Route path={BlogScreen.SCREENNAME} component={BlogScreen} />
                <Route path={ProjectReqScreen.SCREENNAME} component={ProjectReqScreen} />
                <Route path={BrandingAndAdsScreen.SCREENNAME} component={BrandingAndAdsScreen} />
                <Route path={MobileDevelopementScreen.SCREENNAME} component={MobileDevelopementScreen} />
                <Route path={QualityAssurance.SCREENNAME} component={QualityAssurance} />
                <Route path={WebDevelopmentScreen.SCREENNAME} component={WebDevelopmentScreen} />
                <Route path={UIUXScreen.SCREENNAME} component={UIUXScreen} />
                <Route path={StartupIdeaScreen.SCREENNAME} component={StartupIdeaScreen} />
                <Route path={`${BlogDetails.SCREENNAME}/:id`} component={BlogDetails} />
                <Route path={`${PortfolioDetail.SCREENNAME}/:id`} component={PortfolioDetail} />
                <Route path={`${PrivacyPolicy.SCREENNAME}`} component={PrivacyPolicy} />
                <Route path={`${TermsOfUse.SCREENNAME}`} component={TermsOfUse} />
                <Route path='*' exact={true} component={HomeScreen} />
            </Switch>
        </Router>
    )
}
export default HosRoutes;